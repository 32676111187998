import throttle from 'lodash/throttle';

const setVh = () => {
    const vh = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${ vh }px`);
};

const setVw = () => {
    const vw = window.innerWidth * 0.01;

    document.documentElement.style.setProperty('--vw', `${ vw }px`);
};

document.addEventListener('DOMContentLoaded', function () {
    const vh = window.innerHeight * 0.01;
    const vw = window.innerWidth * 0.01;

    document.documentElement.style.setProperty('--vh', `${ vh }px`);
    document.documentElement.style.setProperty('--vw', `${ vw }px`);
    window.addEventListener(
        'resize',
        throttle(function () {
            setVh();
            setVw();
        }, 200),
    );
});
