// lib global css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dropdown/style.css';

import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import 'assets/styles/index.scss';
import LoadingScreen from 'components/loaders/loading-screen';
import PopupContainer from 'components/widgets/popup/container';
import TaooToastContainer from 'components/widgets/toast/ToastContainer';
import { application_type } from 'constants/api';
import TaooServerClient from 'http/axios';
import { milliSecondsToMinutes } from 'services/conversion';
import 'services/onload.js';
import store, { persistor } from 'store/index';
import { SET_USER } from 'store/user/types';
import DraggablePopupContainer, { DraggablePopupSecContainer } from 'components/widgets/draggable-popup/container';
import ErrorBoundaryWrapper from 'components/error/error-boundary/ErrorBoundary';

// import ReloadPrompt from 'app/ReloadPrompt';
import App from './app/App';

document.addEventListener('DOMContentLoaded', () => {
    if (localStorage[`${ application_type }_user`]) {
        const user = JSON.parse(localStorage.getItem(`${ application_type }_user`));

        // eslint-disable-next-line
        // @ts-ignore
        TaooServerClient.defaults.headers.Authorization = `Bearer ${ user.token }`;
        store.dispatch({
            type:    SET_USER,
            payload: user,
        });
    }

    // store onboarding state - resume paused onboarding after 1 hour
    const updateDelay = milliSecondsToMinutes(60);

    const interval = setInterval(() => {
        const state = store.getState();

        if (state.user.onboarded) {
            clearInterval(interval);
        }

        store.dispatch({ type: 'ONBOARDING_RESUME' });
    }, updateDelay);

    // store data fetching - update handling
    // const storeUpdateDelay = milliSecondsToMinutes(5);

    // setInterval(() => {
    //     store.dispatch(toggleStoreUpdate(true));
    // }, storeUpdateDelay);
});

const Main = () => (
    <BrowserRouter>
        <React.StrictMode>
            <ErrorBoundaryWrapper>
                <Provider store={store}>
                    {/*
                        Why use <PersistGate /> ??
                        => Delay the rendering of our app’s UI until the persisted data is available in the Redux store
                    */}
                    <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                        <App />

                        <PopupContainer />
                        <DraggablePopupContainer />
                        <DraggablePopupSecContainer />
                        <TaooToastContainer />

                        {/* <ReloadPrompt /> */}
                    </PersistGate>
                </Provider>
            </ErrorBoundaryWrapper>
        </React.StrictMode>
    </BrowserRouter>
);

ReactDOM.render(
    // eslint-disable-next-line
    // @ts-ignore
    <Main />,
    document.getElementById('root') as HTMLElement,
);

// reportWebVitals(console.log);
