import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { LogManger } from 'services/log';
import ErrorBoundaryFallback from '../error-boundary-fallback/ErrorBoundaryFallback';

const logError = (error: Error, info: { componentStack: string }) => {
    // Do something with the error, e.g. log to an external API

    LogManger.error('ERROR_BOUNDARY: error:', error.message);
    LogManger.error('ERROR_BOUNDARY: info:', info.componentStack);
};

const ErrorBoundaryWrapper = ({ children }: PropsWithChildren) => (
    <ErrorBoundary
        FallbackComponent={ErrorBoundaryFallback}
        onReset={(details) => {
            // Reset the state of your app so the error doesn't happen again
        }}
        onError={logError}
    >
        {children}
    </ErrorBoundary>
);

export default ErrorBoundaryWrapper;
