function DraggablePopupContainer() {
    return (
        <div id="draggable-popup-container" style={{ position: 'relative', width: '100%', height: '100%' }} />
    );
}

export function DraggablePopupSecContainer() {
    return (
        <div id="draggable-popup-sec-container" style={{ position: 'relative', width: '100%', height: '100%' }} />
    );
}

export default DraggablePopupContainer;
