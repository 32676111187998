import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

function TaooToastContainer() {
    const [isRtl, setIsRtl] = useState(false);

    useEffect(() => {
        setIsRtl(document.body.classList.contains('rtl'));
    }, []);

    return (
        <ToastContainer
            position="top-center"
            autoClose={false}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            closeButton={false}
            rtl={isRtl}
            pauseOnFocusLoss
            draggable
            pauseOnHover={false}
            theme="dark"
        />
    );
}

export default TaooToastContainer;
