import { useNavigate } from 'react-router';
import { Config } from 'config';

import PrimaryButton from 'components/form/button/PrimaryButton';
import SecondaryButton from 'components/form/button/SecondaryButton';
import { clearCacheExceptLocalStorage, forceHardReload } from 'app/cache/cacheUtils';
import { getStorageItem } from 'services/storage';
import { BuilderVersionStorageKey } from 'app/cache/useUpdateBuildVersion';

import './errorBoundaryFallback.sass';

const currentBuildVersion = getStorageItem(BuilderVersionStorageKey) ?? 0;

export default function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleReload = () => {
        clearCacheExceptLocalStorage();
        forceHardReload(currentBuildVersion);
    };

    return (
        <div className="eb-fallback-container" role="alert">
            <div>
                <h1>Something went wrong!</h1>
                {

                    Config.isDevelopment ? (
                        <pre>{error.message}</pre>
                    ) : (
                        <p>You need to reload this page.</p>
                    )
                }

                <div className="d-flex flex-column flex-align-center justify-content-center">
                    <PrimaryButton onClick={handleReload}>
                        Reload
                    </PrimaryButton>
                    <SecondaryButton className="mt-3" onClick={handleBack}>
                        Go back
                    </SecondaryButton>
                </div>
            </div>
        </div>
    );
}
